import React, {useState} from 'react';
import logo from '../img/tuedelbeats-logo.png';
import {Link} from 'gatsby'

const Header = () => {
  const [isBoom, setBoom] = useState(false);
  return (
    <>
      <nav className='navbar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          <Link className='navbar-item' to='/'>
            <img src={logo} alt='Tüdel Beats Home' />
          </Link>

          <button className='navbar-burger' aria-label='menu' aria-expanded='false' onClick={
            () => {setBoom(!isBoom)}
          }>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </button>
        </div>

        <div className={`navbar-menu ${isBoom ? 'is-active' : ''}`}>
          <div className="navbar-start">
            <Link className='navbar-item' to='/'>
              Home
            </Link>
            
            <Link className='navbar-item' to='/releases'>
              Releases
            </Link>
            
            <Link className='navbar-item' to='/about'>
              About Me
            </Link>
          </div>
          <div className="navbar-end">
            <Link className='navbar-item' to='/legal'>
              Legal Notice
            </Link>
          </div>
        </div>
      </nav>

      <section className="hero has-bg-img">
        <div className="hero-body my-6">
          <div className="container">
            
          </div>
        </div>
      </section>
    </>
  )
};
  
export {Header};
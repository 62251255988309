import React from 'react';
import { Helmet } from "react-helmet"

const Footer = () => {
  return (
    <>
      <Helmet>
        <script src="https://kit.fontawesome.com/afda2c183e.js" crossorigin="anonymous"></script>
      </Helmet>
      
      <footer className="footer">
        <div className="content has-text-centered">
          <a href="https://tuedelbeats.bandcamp.com/" className="has-text-dark" title="Tüdel Beats on Bandcamp">
            <span className="icon is-large mx-4">
              <i className="fab fa-3x fa-bandcamp"></i>
            </span>
          </a>

          <a href="https://open.spotify.com/artist/0E8b1fcajAOeSDQCfKpGSV?si=pjxd60s9TOqx-eaHucaYbQ" className="has-text-dark" title="Tüdel Beats on Spotify">
            <span className="icon is-large mx-4">
              <i className="fab fa-3x fa-spotify"></i>
            </span>
          </a>

          <a href="https://twitter.com/tuedelbeats" className="has-text-dark" title="Follow Tüdel Beats on Twitter">
            <span className="icon is-large mx-4">
              <i className="fab fa-3x fa-twitter"></i>
            </span>
          </a>

          <a href="https://www.instagram.com/tuedelbeats/" className="has-text-dark" title="Follow Tüdel Beats on Instagram">
            <span className="icon is-large mx-4">
              <i className="fab fa-3x fa-instagram"></i>
            </span>
          </a>

          
        </div>
      </footer>
    </>
  )
};

export {Footer};
import React from "react"
import {Header} from './Header'
import {Footer} from './Footer'
import {Helmet} from "react-helmet"
import './styles.scss';

function Layout({ children, isMinimal }) {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Tüdel Beats is a music lover and producer from Berlin, crafting Instrumental Hip Hop, Lo-Fi and Boombap beats."
        />
        <meta
          name="keywords"
          content="Tüdelbeats, Tudelbeats, Tuedelbeats, Tudel Beats, Tuedel Beats, Hip Hop, Lo-Fi, Boombap, Instrumental, Music"
        />
        <script src="https://kit.fontawesome.com/afda2c183e.js" crossorigin="anonymous"></script>
      </Helmet>
      
      {!isMinimal && <Header />}

      {children}
      <Footer />
    </>
  )
}

export {Layout}